import React, { useContext } from "react";

import classNames from "./master.module.scss";
import { BankContext } from "../../../context/Context";

import { Redirect, useParams } from "react-router-dom";
import Agents from "../../../pages/Agents";
import MyProfile from "../../../pages/MyProfile";
import RahaStore from "../../../pages/RahaStore";
import Legacy from "../../../pages/Legacy";
import LegacyAssociates from "../../../pages/Agency/Legacy";
import HR from "../../../pages/Coverfi/HR";
import Claims from "../../../pages/Coverfi/Claims";
import BrokerAppCRM from "../../../pages/Brokerapp/CRM";
import {
  LoadingAnimationRahaLogo,
  LoadingAnimationRahaLogoFixed,
} from "../../Custom";
import NewProfile from "../../../pages/Employer/CreateNewProfile";
import CompanyProfile from "../../../pages/CompanyProfile";
import NewCompanyProfile from "../../../pages/Employer/CreateNewCompanyProfile";
import SeePolicyRequirments from "../../../pages/Employer/SeePolicyRequirments";
import MyProfileEmployee from "../../../pages/Employee/MyProfile";
import NewProfileEmployee from "../../../pages/Employee/CreateNewProfile";
import CoverfiCompanyProfile from "../../../pages/Coverfi/CompanyProfile";
import EmployerHospital from "../../../pages/Employer/Hospital";
import Dependents from "../../../pages/Coverfi/Dependents";
import Engage from "../../../pages/Engage";
import Overview from "../../../pages/Employer/Overview";
import DashboardAssociate from "../../../pages/Agency/Dashboard";
import CRMAssociate from "../../../pages/Agency/CRM";
import CDBalanceAgency from "../../../pages/Agency/CDBalance";
import ClaimsAssociate from "../../../pages/Agency/Claims";
import CDBalanceTeamlead from "../../../pages/TeamLeadd/CDBalance";
import CRMTeamlead from "../../../pages/TeamLeadd/CRM";
import DashboardTeamLead from "../../../pages/TeamLeadd/Dashboard";
import DashboardVerticalhead from "../../../pages/VerticalHeadd/Dashboard";
import CRMVerticalHead from "../../../pages/VerticalHeadd/CRM";
import CDBalanceVerticalHead from "../../../pages/VerticalHeadd/CDBalance";
import LegacyVerticalHead from "../../../pages/VerticalHeadd/Legacy";
import LegacyTeamLead from "../../../pages/TeamLeadd/Legacy";
import CDBalanceBusinessHead from "../../../pages/BusinessHeadd/CDBalance";
import LegacyBusinessHead from "../../../pages/BusinessHeadd/Legacy";
import DashboardBusinessHead from "../../../pages/BusinessHeadd/Dashboard";
import CRMBusinessHead from "../../../pages/BusinessHeadd/CRM";
import PopupProfileDetails from "../../PopupProfileDetails";
import EmployeeOverview from "../../../pages/Employee/EmployeeOverview";
import EmployeeClaims from "../../../pages/Employee/Claims";
import EmployeeHospital from "../../../pages/Employee/Hospital";
import EmployeeDependents from "../../../pages/Employee/Dependents";
import EmployeeWellness from "../../../pages/Employee/EmployeeWellness";
import CDBalanceAffiliates from "../../../pages/Affiliates/CDBalance";
import DashboardAffiliates from "../../../pages/Affiliates/Dashboard";
import LegacyAffiliates from "../../../pages/Affiliates/Legacy";
import CRMAffiliates from "../../../pages/Affiliates/CRM";
import ClaimsAssociates from "../../../pages/Agency/Claims";
import HospitalAssociates from "../../../pages/Agency/Hospital";
import HospitalTeamlead from "../../../pages/TeamLeadd/Hospital";
import ClaimsTeamlead from "../../../pages/TeamLeadd/Claims";
import ClaimsVerticalhead from "../../../pages/VerticalHeadd/Claims";
import HospitalVerticalhead from "../../../pages/VerticalHeadd/Hospital";
import ClaimsBusinesshead from "../../../pages/BusinessHeadd/Claims";
import HospitalBusinesshead from "../../../pages/BusinessHeadd/Hospital";
import ClaimsAffiliates from "../../../pages/Affiliates/Claims";
import HospitalAffiliates from "../../../pages/Affiliates/Hospital";
import { logoutToSameLogin } from "../../../assest/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import VerifyEmployee from "../../VerifyEmployee";
import WellnessComingSoon from "../../../pages/Wellness";

const Master = ({ child }) => {
  const history = useHistory();
  const { apptype } = useParams();

  const {
    loginData,
    token,
    loggedInApp,
    updateLoading,
    popularAction,
    isProfileFound,
  } = useContext(BankContext);

  if (!loginData && !token) {
    logoutToSameLogin(history);
  }

  return (
    <div
      className={classNames.master}
      style={{
        pointerEvents:
          typeof popularAction == "string" &&
          (popularAction?.includes("edit") || popularAction?.includes("delete"))
            ? "none"
            : "",
      }}
    >
      <VerifyEmployee />
      <PopupProfileDetails />
      {updateLoading == "form" ? (
        <LoadingAnimationRahaLogoFixed />
      ) : updateLoading ? (
        <LoadingAnimationRahaLogo
          height={"100%"}
          position="absolute"
          opacity="0.5"
        />
      ) : (
        ""
      )}
      <div
        className={classNames.contentContainer}
        style={{
          padding:
            window?.location?.pathname == `/app/${apptype}/newbrokerage` ||
            window?.location?.pathname == `/app/${apptype}/newassociate` ||
            window?.location?.pathname == `/app/${apptype}/corporatelead` ||
            window?.location?.pathname == `/app/${apptype}/individuallead` ||
            window?.location?.pathname == `/app/agency/individuallead` ||
            window?.location?.pathname == `/app/agency/corporatelead` ||
            window?.location?.pathname == `/app/agency/newleadclient` ||
            window?.location?.pathname == `/app/agency/hrclient` ||
            window?.location?.pathname == `/app/${apptype}/addnewemployee` ||
            window?.location?.pathname == `/app/${apptype}/feedbackform` ||
            window?.location?.pathname == `/app/${apptype}/newprofile` ||
            window?.location?.pathname == `/app/${apptype}/newcompanyprofile` ||
            (window?.location?.pathname == `/app/${apptype}/companyprofile` &&
              isProfileFound !== "companyprofile")
              ? ""
              : "0px",
        }}
      >
        {window?.location?.pathname == `/app/${apptype}/newbrokerage` ||
        window?.location?.pathname == `/app/${apptype}/newassociate` ||
        window?.location?.pathname == `/app/${apptype}/corporatelead` ||
        window?.location?.pathname == `/app/${apptype}/individuallead` ||
        window?.location?.pathname == `/app/agency/individuallead` ||
        window?.location?.pathname == `/app/agency/corporatelead` ||
        window?.location?.pathname == `/app/agency/newleadclient` ||
        window?.location?.pathname == `/app/agency/hrclient` ||
        window?.location?.pathname == `/app/${apptype}/addnewemployee` ||
        window?.location?.pathname == `/app/${apptype}/feedbackform` ? (
          child
        ) : window?.location?.pathname?.includes("/policyrequirements") ? (
          <SeePolicyRequirments />
        ) : loggedInApp == "employer" &&
          localStorage.getItem("bankState") == "employer" &&
          window?.location?.pathname?.includes("/app/coverfi/") ? (
          window?.location?.pathname?.includes("/overview") ? (
            <Overview />
          ) : window?.location?.pathname?.includes("/members") ? (
            <HR />
          ) : window?.location?.pathname?.includes("/claims") ? (
            <Claims />
          ) : window?.location?.pathname?.includes("/myprofile") ? (
            <MyProfile />
          ) : window?.location?.pathname?.includes("/newprofile") ? (
            <NewProfile />
          ) : window?.location?.pathname?.includes("/wellnessbeta") ? (
            <RahaStore />
          ) : window?.location?.pathname?.includes("/wellness") ? (
            <WellnessComingSoon />
          ) : window?.location?.pathname?.includes("/companyprofile") ? (
            <CoverfiCompanyProfile />
          ) : window?.location?.pathname?.includes("/newcompanyprofile") ? (
            <NewCompanyProfile />
          ) : window?.location?.pathname?.includes("/hospitals") ? (
            <EmployerHospital />
          ) : window?.location?.pathname?.includes("/dependents") ? (
            <Dependents />
          ) : window?.location?.pathname?.includes("/engage") ? (
            <Engage />
          ) : (
            ""
          )
        ) : loggedInApp == "employee" &&
          localStorage.getItem("bankState") == "employee" &&
          window?.location?.pathname?.includes("/app/rahax/") ? (
          window?.location?.pathname?.includes("/overview") ? (
            <EmployeeOverview />
          ) : window?.location?.pathname?.includes("/claims") ? (
            <EmployeeClaims />
          ) : window?.location?.pathname?.includes("/hospitals") ? (
            <EmployeeHospital />
          ) : window?.location?.pathname?.includes("/dependents") ? (
            <EmployeeDependents />
          ) : window?.location?.pathname?.includes("/wellnessbeta") ? (
            <EmployeeWellness />
          ) : window?.location?.pathname?.includes("/wellness") ? (
            <WellnessComingSoon />
          ) : window?.location?.pathname?.includes("/myprofile") ? (
            <MyProfileEmployee />
          ) : window?.location?.pathname?.includes("/newprofile") ? (
            <NewProfileEmployee />
          ) : window?.location?.pathname?.includes("/companyprofile") ? (
            <CompanyProfile />
          ) : window?.location?.pathname?.includes("/newcompanyprofile") ? (
            <NewCompanyProfile />
          ) : (
            ""
          )
        ) : loggedInApp == "associates" &&
          localStorage.getItem("bankState") == "associates" &&
          window?.location?.pathname?.includes("/app/agency/") ? (
          window?.location?.pathname?.includes("/legacy") ? (
            <LegacyAssociates />
          ) : window?.location?.pathname?.includes("/dashboard") ? (
            <DashboardAssociate />
          ) : window?.location?.pathname?.includes("/crm") ? (
            <CRMAssociate />
          ) : window?.location?.pathname?.includes("/cdbalance") ? (
            <CDBalanceAgency />
          ) : window?.location?.pathname?.includes("/companyprofile") ? (
            <CompanyProfile />
          ) : window?.location?.pathname?.includes("/newcompanyprofile") ? (
            <NewCompanyProfile />
          ) : window?.location?.pathname?.includes("/claims") ? (
            <ClaimsAssociates />
          ) : window?.location?.pathname?.includes("/hospitals") ? (
            <HospitalAssociates />
          ) : (
            ""
          )
        ) : loggedInApp == "admin" &&
          localStorage.getItem("bankState") == "admin" &&
          window?.location?.pathname?.includes("/app/brokerapp/") ? (
          window?.location?.pathname?.includes("/staff") ? (
            <Agents />
          ) : window?.location?.pathname?.includes("/crm") ? (
            <BrokerAppCRM />
          ) : window?.location?.pathname?.includes("/insurance") ? (
            <Legacy />
          ) : window?.location?.pathname?.includes("/companyprofile") ? (
            <CompanyProfile />
          ) : window?.location?.pathname?.includes("/newcompanyprofile") ? (
            <NewCompanyProfile />
          ) : (
            ""
          )
        ) : loggedInApp == "teamlead" &&
          localStorage.getItem("bankState") == "teamlead" &&
          window?.location?.pathname?.includes("/app/teamlead/") ? (
          window?.location?.pathname?.includes("/legacy") ? (
            <LegacyTeamLead />
          ) : window?.location?.pathname?.includes("/dashboard") ? (
            <DashboardTeamLead />
          ) : window?.location?.pathname?.includes("/crm") ? (
            <CRMTeamlead />
          ) : window?.location?.pathname?.includes("/cdbalance") ? (
            <CDBalanceTeamlead />
          ) : window?.location?.pathname?.includes("/companyprofile") ? (
            <CompanyProfile />
          ) : window?.location?.pathname?.includes("/newcompanyprofile") ? (
            <NewCompanyProfile />
          ) : window?.location?.pathname?.includes("/claims") ? (
            <ClaimsTeamlead />
          ) : window?.location?.pathname?.includes("/hospitals") ? (
            <HospitalTeamlead />
          ) : (
            ""
          )
        ) : loggedInApp == "verticalhead" &&
          localStorage.getItem("bankState") == "verticalhead" &&
          window?.location?.pathname?.includes("/app/verticalhead/") ? (
          window?.location?.pathname?.includes("/legacy") ? (
            <LegacyVerticalHead />
          ) : window?.location?.pathname?.includes("/dashboard") ? (
            <DashboardVerticalhead />
          ) : window?.location?.pathname?.includes("/crm") ? (
            <CRMVerticalHead />
          ) : window?.location?.pathname?.includes("/cdbalance") ? (
            <CDBalanceVerticalHead />
          ) : window?.location?.pathname?.includes("/companyprofile") ? (
            <CompanyProfile />
          ) : window?.location?.pathname?.includes("/newcompanyprofile") ? (
            <NewCompanyProfile />
          ) : window?.location?.pathname?.includes("/claims") ? (
            <ClaimsVerticalhead />
          ) : window?.location?.pathname?.includes("/hospitals") ? (
            <HospitalVerticalhead />
          ) : (
            ""
          )
        ) : loggedInApp == "businesshead" &&
          localStorage.getItem("bankState") == "businesshead" &&
          window?.location?.pathname?.includes("/app/businesshead/") ? (
          window?.location?.pathname?.includes("/legacy") ? (
            <LegacyBusinessHead />
          ) : window?.location?.pathname?.includes("/dashboard") ? (
            <DashboardBusinessHead />
          ) : window?.location?.pathname?.includes("/crm") ? (
            <CRMBusinessHead />
          ) : window?.location?.pathname?.includes("/cdbalance") ? (
            <CDBalanceBusinessHead />
          ) : window?.location?.pathname?.includes("/companyprofile") ? (
            <CompanyProfile />
          ) : window?.location?.pathname?.includes("/newcompanyprofile") ? (
            <NewCompanyProfile />
          ) : window?.location?.pathname?.includes("/claims") ? (
            <ClaimsBusinesshead />
          ) : window?.location?.pathname?.includes("/hospitals") ? (
            <HospitalBusinesshead />
          ) : (
            ""
          )
        ) : loggedInApp == "affiliates" &&
          localStorage.getItem("bankState") == "affiliates" &&
          window?.location?.pathname?.includes("/app/affiliates/") ? (
          window?.location?.pathname?.includes("/legacy") ? (
            <LegacyAffiliates />
          ) : window?.location?.pathname?.includes("/dashboard") ? (
            <DashboardAffiliates />
          ) : window?.location?.pathname?.includes("/crm") ? (
            <CRMAffiliates />
          ) : window?.location?.pathname?.includes("/cdbalance") ? (
            <CDBalanceAffiliates />
          ) : window?.location?.pathname?.includes("/companyprofile") ? (
            <CompanyProfile />
          ) : window?.location?.pathname?.includes("/newcompanyprofile") ? (
            <NewCompanyProfile />
          ) : window?.location?.pathname?.includes("/claims") ? (
            <ClaimsAffiliates />
          ) : window?.location?.pathname?.includes("/hospitals") ? (
            <HospitalAffiliates />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Master;
